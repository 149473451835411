<mat-card class="header">
    <mat-card-actions class="env-{{siteEnvironment}}">
        {{ siteName }}
        <span class="spacer"></span>
        <button mat-button [matMenuTriggerFor]="menu">
            <mat-icon class="icofont-navigation-menu"></mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item>
            <mat-icon class="icofont-user"></mat-icon> Profile
          </button>
          <button mat-menu-item (click)="logout()">
            <mat-icon class="icofont-logout"></mat-icon> Logout
          </button>
        </mat-menu>
    </mat-card-actions>
</mat-card>