import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SecurityService } from './security.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
   constructor(public securityService: SecurityService, public router: Router) {}
   
   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      return this.securityService.isAuthenticated()
         .pipe(map((result:boolean) => {
               if ( result === true ) {
                  return true;
               } else {
                  this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url }}).then(_ => false);
                  return false;
               }
            })
      )
   }
}