import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, ContentChild, Input, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { LoaderService } from '../../shared/services/loader.service';
@Component({
    selector: 'uik-spinner',
    standalone: true,
    imports: [MatProgressSpinnerModule, AsyncPipe, NgTemplateOutlet],
    templateUrl: './spinner.component.html',
    styleUrl: './spinner.component.scss',
    encapsulation: ViewEncapsulation.ShadowDom,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpinnerComponent {

    constructor(protected loadingService: LoaderService) {
    }
}