import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

(window as any).mermaidFunction = function (node: string, componentId: string) {
    const event = new CustomEvent("mermaid-click", {
        bubbles: true,
        detail: { node, componentId: Number(componentId) },
    });
    window.dispatchEvent(event);
}

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
