import { CdkContextMenuTrigger, CdkMenu, CdkMenuGroup, CdkMenuItem } from '@angular/cdk/menu';
import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute, EventType, NavigationEnd, Router, RouterModule } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AvailablePermissions, ImageSourcePipe, SecurityGuardDirective } from '../../shared';

@Component({
    selector: 'uik-sidemenu',
    standalone: true,
    imports: [CdkContextMenuTrigger, CdkMenu, CdkMenuItem, CdkMenuGroup, RouterModule, ImageSourcePipe, SecurityGuardDirective],
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './sidemenu.component.html',
    styleUrl: './sidemenu.component.scss'
})
export class SidemenuComponent implements OnInit {

    uiConfig = environment.uiConfig;

    public projectCode: string | undefined;
    public processCode: string | undefined;
    public incidentCode: string | undefined;
    public shipmentCode: string | undefined;

    public constructor(public router: Router,
        public activatedRoute: ActivatedRoute,
        public permissions: AvailablePermissions) {

        router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.activatedRoute.firstChild?.paramMap.subscribe((params) => {
                    this.projectCode = params.get('projectCode') || undefined;
                    this.processCode = params.get('processCode') || undefined;
                    this.incidentCode = params.get('incidentCode') || undefined;
                    this.shipmentCode = params.get('shipmentCode') || undefined;
                });
            }
        })
    }



    ngOnInit(): void {
    }


}
