import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ActivatedRoute, ActivatedRouteSnapshot, Route, Router, RouterLink } from '@angular/router';
import { first } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SecurityService, User } from '../../service/security';
import { ImageSourcePipe, SessionService } from '../../shared';

@Component({
  selector: 'uik-header',
  standalone: true,
  imports: [MatIconModule, MatButtonModule, ImageSourcePipe, MatMenuModule, MatCardModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
    public uiConfig = environment.uiConfig;
    public siteVersion: string = environment.system.version;
    public siteEnvironment: string = environment.system.environment;
    public user: User = environment.uiConfig.anonymousUser;

    public siteName: string = environment.uiConfig.site_title;

    public constructor(
        private securityService: SecurityService,
        private sessionService: SessionService,
        private router: Router,
        private activeRoute: ActivatedRoute) {
    }

    ngOnInit(): void {

        this.securityService.getCurrentUser().subscribe((response) => {
            if (response.entity != undefined) {
                if ( this.user.id !== response.entity.id) {
                    this.user = response.entity;
                    this.sessionService.updateSession({ "avatar": response.entity.avatar });
                }
            } else {
                this.sessionService.updateSession({ "avatar": this.user.avatar });
            }
        });
    }

    logout() {
        this.securityService.logout().pipe(first())
            .subscribe(
                (response) => {
                    this.router.navigate(['/auth/login']);
                });
    }

}
