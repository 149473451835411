<div class="menu-container">
    <div class="menu-header">
        <img src="{{ uiConfig.site_logo_large | imageSource }}" class="logo" alt="MAPPS Logo" />
    </div>
    <ul class="menu">
        <li class="menu-item"><a routerLink="/dashboard" routerLinkActive="active"><i class="icon icofont-dashboard-web"></i>Dasboard</a></li>
        <li class="menu-item" *HasPermission="permissions.Operations.CanAccess"><a routerLink="/operations" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }"><i class="icon icofont-building-alt"></i>Operations</a>
            <ul class="sub-menu">
                <li class="menu-item" routerLink="/operations/project/list" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Projects</li>
                @if ( projectCode != undefined ) {
                    <li class="menu-item" style="padding-left: 2rem;" routerLink="/operations/project/{{projectCode}}/workorder/list" routerLinkActive="active">{{projectCode}}</li>
                }
                <li class="menu-item" routerLink="/operations/project/list/status" routerLinkActive="active">Projects by Status</li>
                <li class="menu-item" routerLink="/operations/material/list" routerLinkActive="active">Material</li>
                <li class="menu-item" routerLink="/operations/material/category/list" routerLinkActive="active">Material Categories</li>
                <li class="menu-item" routerLink="/operations/plant/list" routerLinkActive="active">Plants</li>
            </ul>
        </li>
        <li class="menu-item"><a routerLink="/logistics" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }"><i class="icon icofont-delivery-time"></i>Logistics</a>
            <ul class="sub-menu">
                <li class="menu-item" routerLink="/logistics/shipment/list" routerLinkActive="active">Shipments</li>
                @if ( shipmentCode != undefined ) {
                    <li class="menu-item" style="padding-left: 2rem;" routerLink="/logistics/shipment/{{shipmentCode}}/edit" routerLinkActive="active">{{shipmentCode}}</li>
                }
                <li class="menu-item" routerLink="/logistics/carrier/list" routerLinkActive="active">Carriers</li>
            </ul>
        </li>
        <li class="menu-item"><a routerLink="/assetmanagment" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }"><i class="icon icofont-cubes"></i>Asset Managment</a>
            <ul class="sub-menu">
                <li class="menu-item" routerLink="/assetmanagment/dashboard" routerLinkActive="active">Dashboard</li>
                <li class="menu-item" routerLink="/assetmanagment/depot/list" routerLinkActive="active">Depots</li>
                <li class="menu-item" routerLink="/assetmanagment/asset/list" routerLinkActive="active">Assets</li>
                <li class="menu-item" routerLink="/assetmanagment/asset/category/list" routerLinkActive="active">Asset Categories</li>
                <li class="menu-item" routerLink="/assetmanagment/maintenance/calendar" routerLinkActive="active">Maintenance</li>
                <li class="menu-item" routerLink="/assetmanagment/supplier/list" routerLinkActive="active">Suppliers</li>
            </ul>
        </li>
        <li class="menu-item"><a routerLink="/reports" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }"><i class="icon icofont-chart-line-alt"></i>Reports</a>
            <ul class="sub-menu">
                <li class="menu-item" routerLink="/reports" routerLinkActive="active">Reports</li>
            </ul>
        </li>
        <li class="menu-item"><a routerLink="/humancapital" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }"><i class="icon icofont-company"></i>Human Capital</a>
            <ul class="sub-menu">
                <li class="menu-item" routerLink="/humancapital/incident" routerLinkActive="active">Health and Safety</li>
                <li class="menu-item" routerLink="/humancapital/employee/position/list" routerLinkActive="active">Employee Positions</li>
                <li class="menu-item" routerLink="/humancapital/employee/list" routerLinkActive="active">Employees</li>
            </ul>
        </li>
        <li class="menu-item"><a routerLink="/crm" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }"><i class="icon icofont-handshake-deal"></i>Customer Managment</a>
            <ul class="sub-menu">
                <li class="menu-item" routerLink="/crm/customer/list" routerLinkActive="active">Customers</li>
            </ul>
        </li>
        <li class="menu-item"><a routerLink="/system" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }"><i class="icon icofont-gears"></i>System</a>
            <ul class="sub-menu">
                <li class="menu-item" routerLink="/system/dashboard" routerLinkActive="active">Statistics</li>
                <li class="menu-item" routerLink="/system/user/list" routerLinkActive="active">Users</li>
                <li class="menu-item" routerLink="/system/group/list" routerLinkActive="active">Groups and Permissions</li>
                <li class="menu-item" routerLink="/system/configuration/edit" routerLinkActive="active">Configuration</li>
            </ul>
        </li>
    </ul>
</div>