import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { environment } from '../../environments/environment';
import { GlobalConfiguration } from '../shared';

export const serviceConfig: GlobalConfiguration = new GlobalConfiguration(
  environment.apiConfig
);

@NgModule({
  imports:      [ CommonModule ],
  declarations: [  ],
})
export class ServiceModule {
  constructor (@Optional() @SkipSelf() parentModule: ServiceModule) {
    if (parentModule) {
      throw new Error(
        'ServiceModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(): ModuleWithProviders<ServiceModule> {
    return {
      ngModule: ServiceModule,
      providers: [
        { provide: GlobalConfiguration, useValue: serviceConfig }
      ]
    };
  }
}



