import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApplicationNotificationService, NotificationMessageTypes } from '../../shared';
import { SecurityService } from './security.service';
import { TokenService } from './token.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(public tokenService: TokenService,
        public router: Router,
        public securityService: SecurityService,
        public messageService: ApplicationNotificationService) {

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.tokenService.getToken();
        const jwt = this.tokenService.getJwtToken() ?? 'invalid';
        const refreshToken = this.tokenService.getRefreshToken();

        if (!request.headers.has('Content-Type')) {
            if (request.body === null || (request.body !== null && (typeof request.body.get !== 'function'))) {
                request = request.clone({
                    setHeaders: {
                        'content-type': 'application/json'
                    }
                });
            }
        }

        if (token) {
            const headers = new HttpHeaders({
                Authorization: 'Bearer ' + token
            });
            request = request.clone({ headers });
        }

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.status === 400) {
                    this.messageService.sendMessage({
                        messages: error.error.messages,
                        type: NotificationMessageTypes.ErrorMessage,
                        timeout: 2500
                    });
                } else if (error.status === 401) {
                   this.router.navigate(['/auth/login']).then(_ => false);
                } else if (error.status === 403) {
                    this.messageService.sendMessage({
                        type: NotificationMessageTypes.SystemErrorMessage,
                        title: "Invalid permissions",
                        messages: error.message
                    });
                } else if (error.status === 409) {
                    this.messageService.sendMessage({
                        type: NotificationMessageTypes.WarningMessage,
                        messages: error.message,
                        payload: error
                    });
                } else if (error.status > 409) {
                    this.messageService.sendMessage({
                        type: NotificationMessageTypes.SystemErrorMessage,
                        title: "Error while excuting a server call",
                        messages: error.message
                    });
                }
                return throwError(() => new Error(error.message));
            }));
    }
}
