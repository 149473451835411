import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { Title } from '@angular/platform-browser';
import { Router, RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { environment } from '../environments/environment';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { FeatureService, SettingsService } from './service/system';

@Component({
  selector: 'app-root',
  standalone: true,  
  imports: [RouterOutlet, 
            SpinnerComponent],
  styleUrl: './app.component.scss',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent {
    protected title = 'MAPPS';

    constructor(private settingsService: SettingsService,
        private featuresService: FeatureService,
        private router: Router,
        private titleService: Title,
        private translate: TranslateService,
        private materialIconRegistry: MatIconRegistry) {

        materialIconRegistry.registerFontClassAlias('icofont','icofont');
        translate.setDefaultLang('en');
        
        forkJoin([this.settingsService.setGlobalSettings(), this.featuresService.setFeatures()])
            .subscribe(([settingIsSet, featureIsSet]) => {
                if (settingIsSet && featureIsSet) {
                    console.log("Loadded configuration complete");
                }
                this.titleService.setTitle(window.TB_SETTINGS.theme.site_title.toString() || environment.uiConfig.site_title);
            });
    }

    switchLanguage(language: string) {
        this.translate.use(language);
    }
}
