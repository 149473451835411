import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterOutlet } from '@angular/router';
import { forkJoin } from 'rxjs';
import { environment } from '../../../environments/environment';
import { FeatureService, SettingsService } from '../../service/system';
import { HeaderComponent } from '../header/header.component';
import { NotificationMessageComponent } from '../notifications/notification-message.component';
import { SidemenuComponent } from '../sidemenu/sidemenu.component';
import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
    selector: 'app-layout',
    standalone: true,
    imports: [RouterOutlet, HeaderComponent, SidemenuComponent, NotificationMessageComponent, SpinnerComponent],
    templateUrl: './layout.component.html',
    styleUrl: './layout.component.scss'
})
export class LayoutComponent {
    title = 'MAPPS';
    protected environment = environment;

    constructor(private settingsService: SettingsService,
        private featuresService: FeatureService,
        private titleService: Title) {
        forkJoin([this.settingsService.setGlobalSettings(), this.featuresService.setFeatures()])
            .subscribe(([globalSettings, features]) => {
                this.titleService.setTitle(window.TB_SETTINGS.theme.site_title.toString() || environment.uiConfig.site_title);
            });

    }
}
