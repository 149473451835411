import { Routes } from '@angular/router';
import { LayoutComponent } from './components/layout/layout.component';
import { AuthGuard } from './service';

export const routes: Routes = [
    {
        path: 'dashboard', component: LayoutComponent,
        loadChildren: () => import('./dashboard/dashboard.routes').then(m => m.DASHBOARD_ROUTES), 
        canActivate: [AuthGuard]
    },
    {
        path: 'operations', component: LayoutComponent,
        loadChildren: () => import('./operations/operations.routes').then(m => m.OPERATIONS_ROUTES), 
        canActivate: [AuthGuard]
    },
    {
        path: 'logistics', component: LayoutComponent,
        loadChildren: () => import('./logistics/logistics.routes').then(m => m.LOGISTICS_ROUTES), 
        canActivate: [AuthGuard]
    },
    {
        path: 'reports', component: LayoutComponent,
        loadChildren: () => import('./reports/reports.routes').then(m => m.REPORT_ROUTES), 
        canActivate: [AuthGuard]
    },
    {
        path: 'helpdesk', component: LayoutComponent,
        loadChildren: () => import('./helpdesk/helpdesk.routes').then(m => m.HELPDESK_ROUTES), 
        canActivate: [AuthGuard]
    },
    {
        path: 'assetmanagment', component: LayoutComponent,
        loadChildren: () => import('./assetmanagment/assetmanagment.routes').then(m => m.ASSETMANAGMENT_ROUTES), 
        canActivate: [AuthGuard]
    },
    {
        path: 'crm', component: LayoutComponent,
        loadChildren: () => import('./crm/crm.routes').then(m => m.CRM_ROUTES), 
        canActivate: [AuthGuard]
    },
    {
        path: 'humancapital', component: LayoutComponent,
        loadChildren: () => import('./humancapital/humancapital.routes').then(m => m.HUMANCAPITAL_ROUTES), 
        canActivate: [AuthGuard]
    },
    {
        path: 'inventory', component: LayoutComponent,
        loadChildren: () => import('./inventory/inventory.routes').then(m => m.INVENTORY_ROUTES), 
        canActivate: [AuthGuard]
    },
    {
        path: 'system', component: LayoutComponent,
        loadChildren: () => import('./system/system.routes').then(m => m.SYSTEM_ROUTES), 
        canActivate: [AuthGuard]
    },
    { path: 'auth', loadChildren: () => import('./auth/auth.routes').then(m => m.AUTH_ROUTES) },
    { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
];
