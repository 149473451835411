<uik-notification-message></uik-notification-message>
<div class="container">
    <div class="left-menu">
        <uik-sidemenu></uik-sidemenu>
    </div>
    <div class="content-area">
        <uik-header></uik-header>
        <router-outlet></router-outlet>
    </div>
</div>
<footer>
    {{environment.system.environment}} - {{environment.system.version}}
</footer>
<uik-spinner></uik-spinner>
