import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SecurityService } from '../../service/security';
import { ApplicationNotificationService, NotificationMessage, NotificationMessageTypes } from '../../shared';

@Component({
  selector: 'uik-notification-message',
  standalone: true,
  imports: [MatSnackBarModule],
  template: ''
})
export class NotificationMessageComponent implements OnInit, OnDestroy {

    protected subscription!: Subscription;
    public message: NotificationMessage = {
      title: '',
      messages: [''],
      type: NotificationMessageTypes.InformationMessage,
      payload: {}
    }
  
    public support = environment.support;
  
    public reportMessage: boolean = false;
    public encodedMessage: string = '';
    
    public showNotification: Boolean = false;
  
    public constructor(private securityService: SecurityService,
      private router: Router,
      private snackBar: MatSnackBar,
      private messageService: ApplicationNotificationService) {
  
      this.subscription = this.messageService.onMessage().subscribe(message => {
        if (message != null) {
          this.snackBar.openFromComponent(NotificationComponent, { data: message,  duration: message.timeout || 2000, horizontalPosition: 'center', verticalPosition : 'top', panelClass: 'white-snackbar' } );
  
          if ( message.type == NotificationMessageTypes.SystemErrorMessage){
            this.reportMessage = true;
            this.encodedMessage = "Error information: " + btoa( JSON.stringify( this.message.payload ));
          }
        }
      });
    }
  
    ngOnInit(): void {
  
    }
  
    ngOnDestroy(): void {
      this.subscription.unsubscribe();
    }
  }
  
  @Component({
    selector: 'NotificationComponent',
    standalone: true,
    imports: [ MatSnackBarModule ],
    templateUrl: "./notification-message.component.html",
    styleUrls: ['./notification-message.component.scss']
  })
  export class NotificationComponent {
    constructor(@Inject(MAT_SNACK_BAR_DATA) public message: NotificationMessage ) {
    }
  
  }